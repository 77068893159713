import React from 'react';
import '../css/RSVPSection2.css';

const RSVPSection2 = () => {
    return (
        <div className="rsvp-container">
            <h1>贲威宁 & 黄语亭</h1>
            <p>2025年3月8日 - 2025年3月10日</p>
            <p>三亚海棠湾民生威斯汀度假酒店</p>
        </div>
    );
};

export default RSVPSection2;