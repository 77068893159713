import React from 'react';
import '../css/RSVPSection2.css';

const RSVPSection2 = () => {
    return (
        <div className="rsvp-container">
            <h1>Weining & Yuting</h1>
            <p>March 08, 2025 - March 10, 2025</p>
            <p>Westin® Sanya Haitang Bay Resort</p>
        </div>
    );
};

export default RSVPSection2;